import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';
import {
  getCustomer,
  getIntervals,
  getOnDemandEmails,
  getPushNotifications,
  getSMSNotifications,
} from '../../irecommend-lib/actions/communicationActions';
import useSetupUserflow from '../helper/useSetupUserflow';

const useFetchCommunications = () => {
  const dispatch = useDispatch();
  const { customer, intervals, emailTemplates, pushNotifications } = useSelector(
    (state) => state.communicationState,
  );
  const { userflowIsLoading } = useSetupUserflow();

  const intervalsResp =
    intervals.response.length > 0 && typeof intervals.response === 'object'
      ? intervals.response
      : [];
  const onDemandEmailsResp =
    emailTemplates && emailTemplates.response.length > 0 ? emailTemplates.response : [];
  const pushNotificationsResp =
    pushNotifications.response.length > 0 ? pushNotifications.response : [];

  const isLoading = intervals.isLoading || emailTemplates?.isLoading || userflowIsLoading;

  useEffect(() => {
    dispatch(getCustomer());
    dispatch(getIntervals());
    dispatch(getOnDemandEmails());
    dispatch(getPushNotifications());
    dispatch(getSMSNotifications());
    dispatch(getUserflowSignatures());
  }, [dispatch]);

  return {
    customer,
    intervalsResp,
    onDemandEmailsResp,
    pushNotificationsResp,
    isLoading,
  };
};

export default useFetchCommunications;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { css, StyleSheet } from 'aphrodite';
import { Pagination, Button } from '@talentech/components';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/admin/PageHeader';
import { TALENTECH_ICONS } from '../../components/Icons';
import Loader from '../../components/Loader';
import Filter from '../../components/Filter';
import Search from '../../components/Search';
import FetchedPositionsList from '../../components/admin/FetchedPositionsList';
import PositionsList from '../../components/admin/PositionsList';
import EmptyState from '../../components/EmptyState';

import useRemovePosition from '../../hooks/position/useRemovePosition';
import useFetchPosition from '../../hooks/position/useFetchPosition';
import useArchivePosition from '../../hooks/position/useArchivePosition';
import useSetupUserflow from '../../hooks/helper/useSetupUserflow';

import { calculatePageCount } from '../../helpers/utils';
import { isMatchingFilter, isMatchingSearch } from '../../helpers/position';
import useDebounce from '../../hooks/helper/useDebounce';
import { filterTypes } from '../../helpers/position';

const AdminJobsPage = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('1');
  const [currentFilter, setCurrentFilter] = useState(filterTypes.published);
  const [filteredExternalPositionsCount, setFilteredExternalPositionsCount] = useState(0);
  const [currentFilterName, setCurrentFilterName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const {
    positions,
    isFetchLoading,
    recommendations,
    tokenExist,
    externalPositions,
    hideAddPosition,
    onPressFetchJobs,
    onPushPosition,
  } = useFetchPosition();
  const { onDeleteExternalPosition, onDeletePosition } = useRemovePosition();
  const { archiveIsLoading, onArchivePosition } = useArchivePosition();
  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  const { userflowIsLoading } = useSetupUserflow(true);

  const itemsPerPage = 24;
  const isLoading = isFetchLoading || archiveIsLoading || userflowIsLoading;

  const publishedPositionsCount =
    positions.response.length > 0
      ? positions.response.filter((position) => !position.archived).length
      : 0;
  const unpublishedPositionsCount =
    positions.response.length > 0
      ? positions.response.filter((position) => position.archived).length
      : 0;

  const totalPositions = positions.response.length;
  const totalExternalPositions = Number(
    externalPositions.response && externalPositions.response.length,
  );
  const isPositionsActive = selectedTab === '1' || !tokenExist;
  const emptyFetchedContent = !externalPositions.response;

  const filteredPositions =
    positions.response.length > 0
      ? positions.response.filter(
          (position) =>
            isMatchingFilter(position, currentFilter) &&
            isMatchingSearch(position, debouncedSearchTerm),
        )
      : [];

  const filteredExternalPositions = debouncedSearchTerm.trim()
    ? externalPositions.response.filter((externalPosition) =>
        externalPosition.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
      )
    : externalPositions.response;

  const positionsPageCount = filteredExternalPositions
    ? calculatePageCount(itemsPerPage, filteredPositions.length)
    : 0;
  const externalPositionsPageCount = filteredExternalPositions
    ? calculatePageCount(itemsPerPage, filteredExternalPositions.length)
    : 0;
  const showAllPositions = currentFilter === filterTypes.all && totalPositions > 0;
  const showPublishedPositions =
    currentFilter === filterTypes.published && publishedPositionsCount > 0;
  const showUnpublishedPositions =
    currentFilter === filterTypes.unpublished && unpublishedPositionsCount > 0;
  const hasFilteredPositions = debouncedSearchTerm ? filteredPositions.length > 0 : true;

  const showActivePositions =
    isPositionsActive &&
    (showAllPositions || showPublishedPositions || showUnpublishedPositions) &&
    hasFilteredPositions;

  const hasExternalPositions = totalExternalPositions > 0;
  const hasFilteredExternalPositions = debouncedSearchTerm
    ? filteredExternalPositionsCount > 0
    : true;

  const showExternalPositions =
    !isPositionsActive && hasExternalPositions && hasFilteredExternalPositions;

  const displayedExternalPositions = filteredExternalPositions
    ? filteredExternalPositions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const displayedPositions = filteredPositions
    ? filteredPositions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const showEmptyState =
    debouncedSearchTerm &&
    ((selectedTab === '1' && filteredPositions.length === 0) ||
      (selectedTab !== '1' && filteredExternalPositionsCount === 0));

  const tabsOptions = tokenExist
    ? {
        tabs: [
          {
            title: t('generic-text-jobs'),
            id: 1,
          },
          {
            title: t('positionpage-ats-tab'),
            id: 2,
          },
        ],
      }
    : {};

  const dropdownMenuList = [
    {
      title: `${t('generic-text-all')} (${positions.response.length})`,
      onClick: () => {
        setCurrentFilter(filterTypes.all);
        setCurrentFilterName(`${t('generic-text-all')} (${positions.response.length})`);
      },
    },
    {
      title: `${t('positionpage-filter-published')} (${publishedPositionsCount})`,
      onClick: () => {
        setCurrentFilter(filterTypes.published);
        setCurrentFilterName(`${t('positionpage-filter-published')} (${publishedPositionsCount})`);
      },
    },
    {
      title: `${t('positionpage-filter-unpublished')} (${unpublishedPositionsCount})`,
      onClick: () => {
        setCurrentFilter(filterTypes.unpublished);
        setCurrentFilterName(
          `${t('positionpage-filter-unpublished')} (${unpublishedPositionsCount})`,
        );
      },
    },
  ];

  useEffect(() => {
    document.addEventListener('click', handleTabClicks);

    return () => {
      document.removeEventListener('click', handleTabClicks);
    };
  }, []);

  useEffect(() => {
    if (currentFilter === filterTypes.all)
      setCurrentFilterName(`${t('generic-text-all')} (${positions.response.length})`);
    if (currentFilter === filterTypes.published)
      setCurrentFilterName(`${t('positionpage-filter-published')} (${publishedPositionsCount})`);
    if (currentFilter === filterTypes.unpublished)
      setCurrentFilterName(
        `${t('positionpage-filter-unpublished')} (${unpublishedPositionsCount})`,
      );
  }, [positions.response, currentFilter, publishedPositionsCount, unpublishedPositionsCount, t]);

  useEffect(() => {
    const count =
      debouncedSearchTerm.trim() && externalPositions.response
        ? externalPositions.response.filter((externalPosition) =>
            externalPosition.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
          ).length
        : externalPositions.response && externalPositions.response.length;

    setFilteredExternalPositionsCount(count);
  }, [debouncedSearchTerm, externalPositions.response]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentFilter, searchTerm]);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleTabClicks = (e) => {
    if (e.target.id === '1') setSelectedTab('1');
    else if (e.target.id === '2') setSelectedTab('2');
  };

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const createPageHeaderButtons = () => [
    !hideAddPosition
      ? {
          label: <span className="add-position-button-text">{t('positionpage-new-position')}</span>,
          icon: TALENTECH_ICONS.plusCircled,
          onclick: () => history.push(`/admin/jobs/create`),
        }
      : {},
  ];

  return (
    <Loader isLoading={isLoading}>
      <PageHeader
        title={t('generic-text-jobs')}
        buttons={createPageHeaderButtons()}
        tabsOptions={tabsOptions}
      />
      <main aria-label="Main content">
        <div className={css(styles.filterAndSearchWrapper)}>
          {selectedTab === '1' && (
            <Filter dropdownMenuList={dropdownMenuList} currentFilterName={currentFilterName} />
          )}
          {!(selectedTab === '2' && emptyFetchedContent) && (
            <>
              <div className={css(styles.search)}>
                <Search onChange={handleSearchChange} value={searchTerm} />
              </div>
              {tokenExist && selectedTab === '2' && (
                <Button
                  color="primary"
                  label={t('admin-get-external-jobs')}
                  size="sm"
                  className={css(styles.fetchButton)}
                  onClick={onPressFetchJobs}
                  aria-label="Get external jobs button"
                />
              )}
            </>
          )}
        </div>
        {debouncedSearchTerm && (
          <div className={css(styles.results)}>
            {t('positionpage-matching-results')}:
            <strong className={css(styles.number)}>
              {selectedTab === '1' ? filteredPositions.length : filteredExternalPositionsCount}
            </strong>
          </div>
        )}
        {showEmptyState ? (
          <EmptyState
            img="https://storybook.talentech.io/images/svg/marketing-advertising/view-count-magnify.svg"
            alt="Magnifying glass"
            width="110"
            heading={t('positionpage-no-matching-results')}
            description={t('positionpage-no-matching-search')}
          />
        ) : selectedTab === '1' || !tokenExist ? (
          <PositionsList
            displayedPositions={displayedPositions}
            recommendations={recommendations}
            onDeletePosition={onDeletePosition}
            onPushPosition={onPushPosition}
            onArchivePosition={onArchivePosition}
          />
        ) : (
          <FetchedPositionsList
            displayedExternalPositions={displayedExternalPositions}
            onPressFetchJobs={onPressFetchJobs}
            onDeleteExternalPosition={onDeleteExternalPosition}
          />
        )}
      </main>
      <footer aria-label="Footer">
        {(showActivePositions || showExternalPositions) && (
          <div className={css(styles.pagination)}>
            <Pagination
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              pageCount={isPositionsActive ? positionsPageCount : externalPositionsPageCount}
              pageRangeDisplayed={2}
              variant="text"
            />
          </div>
        )}
      </footer>
    </Loader>
  );
};

export default AdminJobsPage;

const styles = StyleSheet.create({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '26px',
    padding: '20px',
    alignItems: 'flex-start',
  },
  filterAndSearchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
    padding: '20px 20px 0px 20px',

    '@media (max-width: 580px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  search: {
    flexGrow: 1,
    '@media (max-width: 580px)': {
      width: '100%',
    },
  },
  number: {
    margin: '5px',
  },
  fetchButton: {
    '@media (max-width: 580px)': {
      width: '100%',
    },
  },
  results: {
    padding: '20px 0px 0px 20px',
  },
  pagination: {
    marginLeft: '10px',
  },
});

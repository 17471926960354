import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useHistory } from 'react-router-dom';

import { ActionButton, Icon } from '@talentech/components';

import DropdownMenu from './DropdownMenu';
import CommunicationItemFields from './CommunicationItemFields';
import { ModalForm } from './ModalForm';
import { TALENTECH_ICONS } from '../Icons';
import {
  extractTemplateType,
  fetchTargetKey,
  fetchChannelKey,
  isLaunchCommunicationType,
  fetchActionKey,
  fetchLaunchTimes,
} from '../../helpers/communication';
import { useTranslation } from 'react-i18next';

const fetchDropdownMenuList = (
  t,
  communicationItem,
  onDemandInviteType,
  type,
  templateType,
  history,
  handleToggleActive,
  setOpenView,
) => {
  const dropdownMenuList =
    !communicationItem.sent && !onDemandInviteType
      ? [
          {
            title: t('communication-dropdown-edit'),
            icon: TALENTECH_ICONS.editLight,
            onClick: () =>
              history.push({
                pathname: '/admin/communication/edit/' + communicationItem._id,
                state: { type, templateType },
              }),
          },
          {
            title: communicationItem.active
              ? t('communication-dropdown-turn-off')
              : t('communication-dropdown-turn-on'),
            icon: TALENTECH_ICONS.power,
            onClick: () => handleToggleActive(communicationItem, type === 'ondemand' && 'ondemand'),
          },
        ]
      : onDemandInviteType
      ? [
          {
            title: t('communication-dropdown-edit'),
            icon: TALENTECH_ICONS.editLight,
            onClick: () =>
              history.push({
                pathname: '/admin/communication/edit/' + communicationItem._id,
                state: { type, templateType },
              }),
          },
        ]
      : [
          {
            title: t('communication-dropdown-view'),
            icon: TALENTECH_ICONS.view,
            onClick: () => setOpenView(true),
          },
        ];
  return dropdownMenuList;
};

const CommunicationItem = (props) => {
  const [toggleState, setToggleState] = useState({});
  const [openView, setOpenView] = useState(false);
  const { communicationItem, handleToggleActive, type, isLaunchDateSet, isLaunched } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const templateType = extractTemplateType(communicationItem.template_unique_name);
  const onDemandInviteType =
    (communicationItem.on_demand && communicationItem.template_type === `invite-user`) ||
    communicationItem.template_type === `invite-admin`;
  const dropdownMenuList = fetchDropdownMenuList(
    t,
    communicationItem,
    onDemandInviteType,
    type,
    templateType,
    history,
    handleToggleActive,
    setOpenView,
  );

  const handleTitle = (communicationItem) =>
    type === 'ondemand'
      ? t(`communication-title-${communicationItem.template_type}`)
      : t(`communication-title-${templateType}`);

  const handleAction = (communicationItem) => {
    const isLaunchType = isLaunchCommunicationType(templateType);
    if (isLaunchDateSet && isLaunchType && (!isLaunched || templateType === 'first-update'))
      return fetchLaunchTimes(communicationItem, templateType);

    return (
      <>
        <Icon icon={handleActionIcon(communicationItem)} />
        <span>{t(fetchActionKey(templateType, isLaunchType, isLaunchDateSet))}</span>
      </>
    );
  };

  const handleOnClick = () =>
    (!communicationItem.sent || onDemandInviteType) &&
    history.push({
      pathname: '/admin/communication/edit/' + communicationItem._id,
      state: { type, templateType },
    });

  const handleOpenDropdown = (id) =>
    setToggleState((open) => ({
      ...open,
      [id]: !open[id],
    }));

  const handleCancel = () => setOpenView(false);

  const handleActionIcon = (communicationItem) => {
    if (communicationItem.interval > 0 || templateType === 'vacant-position-reminder')
      return TALENTECH_ICONS.recurring;

    return TALENTECH_ICONS.calender;
  };

  return (
    <>
      {openView && (
        <ModalForm
          showModalForm={openView}
          title={t('communication-preview-title')}
          subtitle={t('communication-preview-desc')}
          content={<CommunicationItemFields interval={communicationItem} t={t} />}
          labelCancel={t('generic-button-cancel')}
          handleCancel={handleCancel}
        />
      )}
      <div className={css(styles.communicationItem)}>
        <div className={css([styles.communicationItemPropsWrapperFirst, styles.flex3])}>
          <div className={css(styles.communicationItemName)}>
            {!communicationItem.sent && !onDemandInviteType && (
              <span className="form-check form-switch d-inline">
                <input
                  onChange={() =>
                    handleToggleActive(communicationItem, type === 'ondemand' && 'ondemand')
                  }
                  className="form-check-input"
                  style={{ cursor: 'pointer' }}
                  type="checkbox"
                  id="toggleCommunication"
                  checked={communicationItem.active}
                  aria-label="Toggle email"
                />
              </span>
            )}
            <span
              className={css([
                styles.communicationItemNameLabel,
                (!communicationItem.sent || onDemandInviteType) && styles.linkHover,
              ])}
              onClick={handleOnClick}
            >
              {handleTitle(communicationItem)}
            </span>
          </div>
          <div className={css(styles.communicationItemProps)}>
            {handleAction(communicationItem)}
          </div>
        </div>
        <div
          className={css([
            styles.communicationItemPropsWrapperSecond,
            styles.showOnBiggerScreen,
            styles.flex2,
          ])}
        >
          <div className={css(styles.communicationItemPropsFirst)}>
            <div className={css(styles.communicationItemProps)}>
              <Icon icon={TALENTECH_ICONS.multipleUsers} />
              <span>{t(fetchTargetKey(templateType))}</span>
            </div>
            <div className={css(styles.communicationItemProps)}>
              <Icon icon={TALENTECH_ICONS.communicationChannel} />
              <span>{t(fetchChannelKey(templateType))}</span>
            </div>
          </div>
          <div className={css(styles.communicationItemPropsSecond)}>
            <ActionButton
              icon={TALENTECH_ICONS.more}
              variant="vertical"
              size="lg"
              className={css(styles.dropdownOptionsButton)}
              onClick={() => handleOpenDropdown(communicationItem._id)}
              aria-label="Options"
            />
            {toggleState[communicationItem._id] && (
              <DropdownMenu dropdownMenuList={dropdownMenuList} setToggleState={setToggleState} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CommunicationItem;
const styles = StyleSheet.create({
  communicationItem: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderBottom: '1px solid rgba(214, 214, 220, 1)',
    padding: '1.15rem 1rem',
  },
  communicationItemPropsWrapperFirst: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
    },
  },
  communicationItemPropsWrapperSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1100px)': {},
  },
  communicationItemName: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
  },
  communicationItemNameLabel: {
    textDecoration: 'underline',
    fontWeight: 600,
  },
  linkHover: {
    cursor: 'pointer',
  },
  communicationItemProps: {
    flex: 1.5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    lineHeight: 3,
  },
  communicationItemPropsFirst: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  dropdownOptionsButton: {
    padding: 0,
    paddingTop: '1rem',
    paddingBottom: '.55rem',
    paddingLeft: '.45rem',
    paddingRight: '.45rem',
    height: '35px',
    width: '35px',
    borderRadius: '50%',
  },
  showOnBiggerScreen: {
    '@media (max-width: 500px)': {
      display: 'none',
    },
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flex3: {
    flex: 3,
  },
  flex2: {
    flex: 2,
  },
});

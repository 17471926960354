import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMultipleRecommendationsForPosition } from '../../irecommend-lib/actions/recommendationActions';
import {
  getExternalPositions,
  getPositions,
  pushPosition,
} from '../../irecommend-lib/actions/positionActions';
import { getUser, getUserTenants } from '../../irecommend-lib/actions/userActions';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';
import { getIntegrationType, getToken } from '../../irecommend-lib/actions/integrationActions';
import { getCustomer } from '../../irecommend-lib/actions/communicationActions';
import { useTranslation } from 'react-i18next';

const useFetchPosition = (props) => {
  const { recommendationsForPositions } = useSelector((state) => state.recommendationState);
  const { token, integrationType } = useSelector((state) => state.integrationState);
  const { user } = useSelector((state) => state.userState);
  const { t } = useTranslation();
  const { customer } = useSelector((state) => state.communicationState);
  const { positions, externalPositions, positionPush } = useSelector(
    (state) => state.positionState,
  );

  const dispatch = useDispatch();

  const recommendations =
    Object.keys(recommendationsForPositions.response).length > 0
      ? recommendationsForPositions.response
      : [];
  const isFetchLoading =
    positions.isLoading ||
    user.isLoading ||
    externalPositions.isLoading ||
    customer.isLoading ||
    positionPush.isLoading;

  // Make sure that "Fetch jobs" button is visible/invisible depending on integration status
  const tokenExist = Object.keys(token.response).length !== 0 ? token.response.tokenexist : null;
  const hideAddPosition =
    integrationType.response.length > 0 &&
    (integrationType.response[0] === 20 || integrationType.response[0] === 30);

  useEffect(() => {
    dispatch(getUserTenants());
    dispatch(getUserflowSignatures());
    dispatch(getUser());
    dispatch(getPositions());
    dispatch(getToken());
    dispatch(getIntegrationType());
    dispatch(getCustomer());
  }, [dispatch]);

  useEffect(() => {
    if (positions.response.length > 0) {
      for (const position of positions.response) {
        dispatch(getMultipleRecommendationsForPosition(position.idpositions));
      }
    }
  }, [dispatch, positions.response.length, positions.response]);

  useEffect(() => {
    if (positionPush.error) alert(t('generic-error-default-message'));
  }, [positionPush.error, t]);

  // Makes API-call to TeamTailor API
  const onPressFetchJobs = () => {
    dispatch(getExternalPositions());
  };

  const onPushPosition = (id, pushTypes) => {
    dispatch(pushPosition(id, { Push: pushTypes }));
  };

  return {
    positions,
    isFetchLoading,
    recommendations,
    tokenExist,
    externalPositions,
    hideAddPosition,
    onPressFetchJobs,
    onPushPosition,
  };
};

export default useFetchPosition;

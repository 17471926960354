import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import BubbleSection from '../components/BubbleSection';
import Button from '../components/Button';
import MainWrapper from '../components/MainWrapper';
import Loader from '../components/Loader';
import EmptyState from '../components/EmptyState';
import { Color, defaultText, Font } from '../config/styles';
import iconInfo from '../assets/icons/icon-info.png';

import { getPosition } from '../irecommend-lib/actions/positionActions';
import { getUserIfNeeded } from '../irecommend-lib/actions/userActions';
import { IntlNetwork } from '../helpers/language';

const PositionPage = () => {
  const [readMore, setReadMore] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const position = useSelector((state) => state.positionState.position.response);
  const isLoading = useSelector((state) => state.positionState.position.isLoading);
  const user = useSelector((state) => state.userState.user.response);
  const isPositionArchived = position?.archived;

  useEffect(() => {
    dispatch(getUserIfNeeded());
    dispatch(getPosition(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (position === 'Unauthorized customer') {
      history.push('/');
    }
  }, [position, history]);

  // Handler for read more button
  const onPressReadMore = () => {
    setReadMore(true);
  };

  const onPressRecommend = () => {
    history.push(`/positions/${id}/recommend`);
  };

  const onPressLinkedin = () => {
    if (position.linkedinquery) {
      window.open(position.linkedinquery, 'ireclinkedin');
    }
  };

  let formattedReward = position.reward;
  if (position.rewardtype) formattedReward += ' ' + position.rewardtype;

  // Prepare listing of skills
  let skills = null;

  if (position.skills) {
    skills = position.skills.split(';').map((skill, key) => (
      <div key={key} className={css(styles.descriptionText)}>
        {'\u2022'} {skill.trim()}
      </div>
    ));
  }

  // Styling for description default and expanded

  const descriptionStyles = readMore
    ? [styles.description, styles.descriptionExpanded]
    : styles.description;

  return (
    <Loader isLoading={isLoading}>
      {isPositionArchived ? (
        <EmptyState
          img="https://storybook.talentech.io/images/svg/logistics/package-attention.svg"
          width="170"
          heading={t('recommendedpage-position-not-found-title')}
          description={t('recommendedpage-position-not-found-description')}
        />
      ) : (
        <MainWrapper className={css(styles.container)}>
          {!isLoading && (
            <div className={css(styles.scrollView)}>
              <div className={css(defaultText.h2, styles.title)}>{position.title}</div>

              <div className={css(styles.rewardWrap)}>
                <div className={css(styles.rewardLabel)}>
                  {IntlNetwork(t, 'generic-label-reward')}
                </div>
                <div className={css(styles.rewardTitle)}>{formattedReward}</div>
              </div>

              <BubbleSection style={styles.descriptionWrap}>
                <div className={css(styles.logotypeWrap)}>
                  {user && <img src={user.companyimage} className={css(styles.logotype)} alt="" />}
                </div>

                <div className={css(styles.descriptionLabel)}>
                  {t('positionpage-description-label').toUpperCase()}
                </div>

                <div className={css(descriptionStyles)}>
                  {skills}

                  {readMore && (
                    <div>
                      <div className={css(styles.descriptionText)}>
                        {ReactHtmlParser(position.text)}
                      </div>
                      <hr className={css(styles.hr)} />
                      <div className={css(styles.contactSection)}>
                        <img src={iconInfo} className={css(styles.i)} alt="" />
                        <p>
                          {IntlNetwork(t, 'recommendedpage-section1-onReadAll-footer-knowMore')}
                        </p>
                        <p>
                          {IntlNetwork(t, 'recommendedpage-section1-onReadAll-footer-description', {
                            name: position.contactname,
                          })}
                        </p>
                        <p>
                          {t('recommendedpage-section1-onReadAll-footer-info', {
                            email: position.contactmail,
                            phone: position.contacttelephone,
                          })}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {!readMore && (
                  <Button
                    onPress={onPressReadMore}
                    title={t('generic-button-readmore')}
                    type="linkInline"
                    style={styles.readMoreButton}
                  />
                )}
              </BubbleSection>

              <div className={css(styles.containerButtons)}>
                <Button
                  style={styles.recommendButton}
                  onPress={() => onPressRecommend(id)}
                  title={t('generic-button-recommend')}
                />

                <Button
                  type="secondary"
                  style={styles.recommendButton}
                  onPress={onPressLinkedin}
                  title={t('tablinkedin-button-search')}
                />
              </div>
            </div>
          )}
        </MainWrapper>
      )}
    </Loader>
  );
};

export default PositionPage;

const styles = StyleSheet.create({
  contactSection: {
    textAlign: 'center',
    fontSize: '.8em',
    marginTop: '2em',
  },
  container: {
    flex: 1,
    backgroundColor: Color.baseGray,
  },
  description: {
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    width: '100%',
  },
  descriptionExpanded: {
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
  },
  descriptionLabel: {
    backgroundColor: Color.purple,
    color: Color.primary,
    fontFamily: Font.defaultFont,
    fontSize: 11,
    letterSpacing: 0.4,
    textAlign: 'center',
    paddingBottom: 13,
    paddingTop: 13,
    width: '100%',
  },
  descriptionText: {
    color: Color.dark,
    fontFamily: Font.defaultFont,
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: 9,
    whiteSpace: 'pre-line',
  },
  descriptionWrap: {
    alignItems: 'flex-start',
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
  },
  hr: {
    borderColor: '#F5F8FA',
    borderWidth: 1,
    borderStyle: 'solid',
    marginTop: 30,
  },
  i: {
    width: 32,
    height: 32,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  logotype: {
    // height: 24,
    maxWidth: 100,
  },
  logotypeWrap: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 94,
    justifyContent: 'center',
    width: '100%',
    padding: '1em 0',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  recommendButton: {
    width: '49%',
  },
  readMoreButton: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: '15px 0',
    borderTop: `1px solid ${Color.border}`,
    width: '100%',
  },
  rewardLabel: {
    color: Color.dark,
    fontFamily: Font.defaultFont,
    fontSize: 13,
  },
  rewardTitle: {
    color: Color.dark,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 13,
  },
  rewardWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 30,
  },
  scrollView: {
    padding: 20,
  },
  title: {
    paddingTop: 25,
    textAlign: 'center',
    marginBottom: 12,
  },
});

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { postPositionArchive, getPositions } from '../../irecommend-lib/actions/positionActions';

const useArchivePosition = () => {
  const { positionPost } = useSelector((state) => state.positionState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (positionPost.response === '') {
      dispatch(getPositions());
      dispatch({ type: 'POST_POSITION_ARCHIVE_RESET' });
    }
  }, [dispatch, positionPost.response]);

  const onArchivePosition = (id) => {
    dispatch(postPositionArchive(id));
  };

  return { archiveIsLoading: positionPost.isLoading, onArchivePosition };
};

export default useArchivePosition;

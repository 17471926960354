import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePosition,
  getPositions,
  removeExternalPosition,
} from '../../irecommend-lib/actions/positionActions';

const useRemovePosition = () => {
  const { externalPositions, positionDelete } = useSelector((state) => state.positionState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!positionDelete.error && Object.keys(positionDelete.response).length > 0)
      dispatch(getPositions());
  }, [dispatch, positionDelete.error, positionDelete.response]);

  const onDeleteExternalPosition = (id) => {
    const updatedExternalPositions = externalPositions.response.filter(
      (position) => position.ID !== id,
    );
    dispatch(removeExternalPosition(updatedExternalPositions));
  };
  const onDeletePosition = (id) => {
    dispatch(deletePosition(id));
  };

  return { onDeleteExternalPosition, onDeletePosition };
};

export default useRemovePosition;

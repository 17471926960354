export const isMatchingFilter = (user, currentFilter) => {
  return (
    currentFilter === filterTypes.all ||
    (currentFilter === filterTypes.admins && user.usertype > 0) ||
    (currentFilter === filterTypes.activatedUsers && user.registered) ||
    (currentFilter === filterTypes.notActivatedUsers && !user.registered)
  );
};

export const isMatchingSearch = (user, searchTerm) => {
  return (
    user.givenname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.familyname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.mail.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const filterTypes = {
  all: 'all',
  activatedUsers: 'activatedUsers',
  admins: 'admins',
  notActivatedUsers: 'notActivatedUsers ',
};

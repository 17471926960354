import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { css, StyleSheet } from 'aphrodite';
import { Pagination } from '@talentech/components';

import Loader from '../../components/Loader';
import InvitationFields from '../../components/admin/InvitationFields';
import PageHeader from '../../components/admin/PageHeader';
import InvitedMemberHeader from '../../components/admin/InvitedMemberHeader';
import InvitedMemberList from '../../components/admin/InvitedMemberList';
import ToastMessage from '../../components/ToastMessage';
import { ModalForm } from '../../components/admin/ModalForm';
import { TALENTECH_ICONS } from '../../components/Icons';
import Filter from '../../components/Filter';
import Search from '../../components/Search';
import EmptyState from '../../components/EmptyState';

import { getCustomer } from '../../irecommend-lib/actions/communicationActions';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';
import { getUser, getUserTenants, getUsers } from '../../irecommend-lib/actions/userActions';
import Utils from '../../irecommend-lib/utils';

import useCreateInvite from '../../hooks/invite/useCreateInvite';
import useRemoveInvite from '../../hooks/invite/useRemoveInvite';
import useSetupUserflow from '../../hooks/helper/useSetupUserflow';
import useShowToast from '../../hooks/helper/useShowToast';
import useToggleUserAdmin from '../../hooks/invite/useToggleUserAdmin';
import useFetchBreadcrumb from '../../hooks/helper/useFetchBreadcrumb';
import useDebounce from '../../hooks/helper/useDebounce';

import { isMatchingFilter, isMatchingSearch } from '../../helpers/users';
import { filterTypes } from '../../helpers/users';
import { calculatePageCount } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

const InvitationPage = (props) => {
  const [state, setState] = useState({
    showModalForm: false,
    isSingleInviteModal: false,
    showToast: false,
    error: false,
    message: '',
    isToggleAdmin: false,
  });
  const [formData, setFormData] = useState({
    familyname: '',
    givenname: '',
    email: '',
    admin: false,
  });
  const { users } = useSelector((state) => state.userState);
  const { customer } = useSelector((state) => state.communicationState);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(filterTypes.all);
  const [currentFilterName, setCurrentFilterName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { createInvite, inviteIsLoading } = useCreateInvite(setState, t);
  const { removeInvite, removeIsloading } = useRemoveInvite(setState, t);
  const { handleToggleUserAdmin, toggleIsLoading } = useToggleUserAdmin();
  const { closeToast } = useShowToast(setState, t);
  const { userflowIsLoading } = useSetupUserflow();
  const breadcrumbs = useFetchBreadcrumb('users', t);

  const usersArr = Array.isArray(users.response) && users.response.length > 0 ? users.response : [];
  const totalUsers = usersArr.length;
  const adminUsersCount = usersArr.filter((user) => user.usertype > 0).length;
  const activatedUsersCount = usersArr.filter((user) => user.registered === true).length;
  const notActivatedUsersCount = usersArr.filter((user) => user.registered === false).length;
  const customerLaunchDate = customer.response.customer?.launch_date;
  const isCustomerLaunched = customerLaunchDate < Math.floor(Date.now() / 1000);

  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  const itemsPerPage = 24;
  const loading =
    users.isLoading || inviteIsLoading || userflowIsLoading || toggleIsLoading || removeIsloading;

  useEffect(() => {
    dispatch(getUserTenants());
    dispatch(getCustomer());
    dispatch(getUserflowSignatures());
    dispatch(getUsers());
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentFilter === filterTypes.all) {
      setCurrentFilterName(`${t('generic-text-all')}  (${totalUsers})`);
    } else if (currentFilter === filterTypes.admins) {
      setCurrentFilterName(`${t('generic-text-administrator')} (${adminUsersCount})`);
    } else if (currentFilter === filterTypes.activatedUsers) {
      setCurrentFilterName(`${t('generic-text-activated')} (${activatedUsersCount})`);
    } else if (currentFilter === filterTypes.notActivatedUsers) {
      setCurrentFilterName(`${t('generic-text-invited')}  (${notActivatedUsersCount})`);
    }
  }, [totalUsers, currentFilter, adminUsersCount, activatedUsersCount, notActivatedUsersCount, t]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentFilter, searchTerm]);

  const handleSubmitForm = () => {
    // Empty fields validation
    if (formData.givenname === '' || formData.familyname === '' || formData.email === '')
      return alert(t('invitationcontainer-validation-empty'));
    // E-mail validation
    if (!Utils.validateEmail(formData.email)) return alert(t('generic-validation-email'));

    const newData = JSON.stringify(formData);

    createInvite(newData);
    setState((state) => ({ ...state, showModalForm: false }));
    setFormData({ familyname: '', givenname: '', email: '', admin: false });
  };

  // DELETE invite - Since user is directly activated after invitation we could just make it inactive
  const handleDeleteInvite = (data, id) => {
    handleRemoveUser(data, id);
  };

  // REMOVE user - Removing user means setting it inactive.
  const handleRemoveUser = (data, id) => {
    const formData = {
      Active: false,
      Familyname: data.familyname,
      Givenname: data.givenname,
      Tagline: data.tagline,
      Mail: data.mail,
      Telephone: data.telephone,
      Picture: data.picture, // Loaded image for presentation
      Team: data.team.href.split('/')[3],
    };

    removeInvite(formData, id);
  };

  // Close modal form
  const handleCancelForm = () => {
    setState((prev) => ({ ...prev, showModalForm: false }));
    setFormData({ familyname: '', givenname: '', email: '', admin: false });
  };

  const filteredUsers =
    users.response.length > 0
      ? users.response.filter(
          (user) =>
            isMatchingFilter(user, currentFilter) && isMatchingSearch(user, debouncedSearchTerm),
        )
      : [];
  const usersPageCount = calculatePageCount(itemsPerPage, filteredUsers.length);

  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Create page header buttons
  const createPageHeaderButtons = () => {
    const buttons = [
      {
        label: t('invitationpage-new-user'),
        icon: TALENTECH_ICONS.plusCircled,
        onclick: () =>
          setState((state) => ({
            ...state,
            showModalForm: true,
          })),
      },
      {
        label: t('invitationpage-import-users'),
        icon: TALENTECH_ICONS.fileUpload,
        onclick: () => {
          history.push('/admin/invites/import');
        },
      },
    ];
    return buttons;
  };

  const dropdownMenuList = [
    {
      title: `${t('generic-text-all')} (${totalUsers})`,
      onClick: () => {
        setCurrentFilter(filterTypes.all);
        setCurrentFilterName(`${t('generic-text-all')} (${totalUsers})`);
      },
    },
    {
      title: `${t('generic-text-activated')} (${activatedUsersCount})`,
      onClick: () => {
        setCurrentFilter(filterTypes.activatedUsers);
        setCurrentFilterName(`${t('generic-text-activated')} (${activatedUsersCount})`);
      },
    },
    {
      title: `${t('generic-text-administrator')} (${adminUsersCount})`,
      onClick: () => {
        setCurrentFilter(filterTypes.admins);
        setCurrentFilterName(`${t('generic-text-administrator')} (${adminUsersCount})`);
      },
    },
    {
      title: `${t('generic-text-invited')}  (${notActivatedUsersCount})`,
      onClick: () => {
        setCurrentFilter(filterTypes.notActivatedUsers);
        setCurrentFilterName(`${t('generic-text-invited')} (${notActivatedUsersCount})`);
      },
    },
  ];

  return (
    <Loader isLoading={loading}>
      <PageHeader
        title={t('admin-header-invites')}
        buttons={createPageHeaderButtons()}
        breadcrumbs={breadcrumbs}
      />
      <ToastMessage
        isShowToast={state.showToast}
        message={state.message}
        closeToast={closeToast}
        type={state.error ? 'danger' : 'success'}
      />

      <ModalForm
        showModalForm={state.showModalForm}
        title={t('invitationpage-new-user')}
        content={<InvitationFields setFormData={setFormData} />}
        labelCancel={t('generic-button-cancel')}
        labelSubmit={t('generic-text-save')}
        handleCancel={handleCancelForm}
        handleSubmit={handleSubmitForm}
      />
      <main aria-label="Main content">
        <div className={css(styles.filterAndSearchWrapper)}>
          <Filter dropdownMenuList={dropdownMenuList} currentFilterName={currentFilterName} />
          <div className={css(styles.search)}>
            <Search onChange={handleSearchChange} value={searchTerm} />
          </div>
        </div>
        {debouncedSearchTerm && (
          <div className={css(styles.results)}>
            {t('positionpage-matching-results')}:
            <strong className={css(styles.number)}>{filteredUsers.length}</strong>
          </div>
        )}
        <InvitedMemberHeader />
        {displayedUsers.length > 0 ? (
          displayedUsers.map((user) => (
            <InvitedMemberList
              handleDeleteInvite={handleDeleteInvite}
              handleRemoveUser={handleRemoveUser}
              handleToggleUserAdmin={handleToggleUserAdmin}
              key={user.iduser}
              type="list"
              user={user}
              isCustomerLaunched={isCustomerLaunched}
            />
          ))
        ) : (
          <EmptyState
            img="https://storybook.talentech.io/images/svg/marketing-advertising/view-count-magnify.svg"
            alt="magnifying glass"
            width="110"
            heading={t('positionpage-no-matching-results')}
            description={t('positionpage-no-matching-search')}
          />
        )}
      </main>
      <footer aria-label="Page footer">
        {filteredUsers.length > 0 && (
          <div className={css(styles.pagination)}>
            <Pagination
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              pageCount={usersPageCount}
              pageRangeDisplayed={2}
              variant="text"
            />
          </div>
        )}
      </footer>
    </Loader>
  );
};

export default InvitationPage;

const styles = StyleSheet.create({
  filterAndSearchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
    padding: '20px 20px 0px 20px',

    '@media (max-width: 580px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  search: {
    flexGrow: 1,
    '@media (max-width: 580px)': {
      width: '100%',
    },
  },
  number: {
    margin: '5px',
  },
  results: {
    padding: '20px 0px 0px 20px',
  },
  pagination: {
    marginLeft: '10px',
    marginTop: '15px',
  },
});

import auth0 from 'auth0-js';
import i18next from 'i18next';

const auth0User = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
});

/**
 * Get Auth0 credentials from localStorage. Async because of how AsyncStorage works in React Native.
 * @return {object} - Credentials - as received from Auth0
 */
export const getCredentials = async () => {
  let credentials = await localStorage.getItem('@irecommend:credentials');
  credentials = credentials == null || credentials === 'undefined' ? {} : JSON.parse(credentials);

  return credentials;
};

/**
 * Get Auth0 credentials from localStorage synchronous
 * @return {object} - Credentials - as received from Auth0
 */
export const getCredentialsSync = () => {
  let credentials = localStorage.getItem('@irecommend:credentials');
  credentials = credentials == null || credentials === 'undefined' ? {} : JSON.parse(credentials);

  return credentials;
};

/**
 * Remove Auth0 credentials from localStorage (logout)
 */
export const removeCredentials = () => {
  localStorage.removeItem('@irecommend:credentials');
};

/**
 * Remove TID credentials from localStorage (logout)
 */
export const removeTidCredentials = () => {
  window.location = `${window.origin}/auth/logout-oidc`;
  localStorage.clear();
};
/**
 * Store Auth0 credentials in localStorage
 * @param {object} credentials - Credentials from Auth0
 * @param {boolean} merge - Merge stored credentials with new data
 */
export const storeCredentials = async (credentials, merge = false) => {
  if (merge) {
    let storedCredentials = await getCredentials();

    if (storedCredentials) {
      Object.assign(credentials, storedCredentials);
    }
  }

  localStorage.setItem('@irecommend:credentials', JSON.stringify(credentials));
};

/**
 * Store user customer type in localStorage
 * @param {object} customerType - Customer type
 */
export const storeCustomerType = (customerType) => {
  localStorage.setItem('@irecommend:customerType', customerType);
};

/**
 * Return user customer type from localStorage
 */
export const getCustomerType = () => {
  return localStorage.getItem('@irecommend:customerType');
};

/**
 * Return redirect route from localStorage
 */
export const getRedirectPathFromLocalStorage = () => {
  const defaultRoute = '/';
  const redirectPath = localStorage.getItem('@irecommend:redirectTo');
  if (redirectPath) return redirectPath;

  return defaultRoute;
};

/**
 * Open admin link in new window
 */
export const openAdmin = async () => {
  window.location =
    process.env.REACT_APP_APP_ENVIRONMENT === 'dev'
      ? `${window.origin}/admin`
      : `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_APP_ENVIRONMENT}${process.env.REACT_APP_ADMIN_BASE_URL}`;
};

//TODO: Remove this function when all components are using the new language system. This is needed in ruleActions
export const getLanguage = () => i18next.language;
/**
 * Parse hash received from Auth0 after authentication
 */
export const parseAuth0Hash = () => {
  return new Promise((resolve, reject) => {
    auth0User.parseHash(function (err, authResult) {
      if (authResult && authResult.idTokenPayload) {
        window.location.hash = '';
        resolve(authResult);
      }

      reject();
    });
  });
};

/**
 * Get all read position ID's from localStorage
 * @return {array} - readPositions - List of all positions ID's that has been read.
 */
export const getReadPositions = async () => {
  let readPositions = await localStorage.getItem('@irecommend:readPositions');
  readPositions = readPositions == null ? [] : JSON.parse(readPositions);

  return readPositions;
};

/**
 * Remove read positions from localStorage (logout)
 */
export const removeReadPositions = () => {
  localStorage.removeItem('@irecommend:readPositions');
};

/**
 * Keeps track of read positions. Stored in localStorage.
 * @param {object} id - Position ID
 */
export const storeReadPosition = async (id) => {
  // Merge with current read positions
  let readPositions = await getReadPositions();

  // Only add read position if it doesn't already exist
  if (readPositions.indexOf(id) !== -1) {
    return;
  }

  readPositions.push(id);
  localStorage.setItem('@irecommend:readPositions', JSON.stringify(readPositions));
};

/**
 * Init authentication with Auth0
 */
export const authenticateUser = (callbackURL, scope, connection, meta = {}) => {
  return auth0User.authorize({
    redirectUri: callbackURL,
    scope: scope,
    audience: 'https://api.irecommend.se',
    responseType: 'token id_token',
    connection: connection,
    meta: JSON.stringify(meta),
  });
};

export const redirectToLoginPage = (store, error = false, status = null) => {
  if (error && status === 404) {
    redirectToAccessDeniedPage();
    return;
  }
  localStorage.removeItem('@irecommend:credentials');
  window.location = `${window.origin}/login`;
};
export const redirectToAccessDeniedPage = () => {
  window.location = `${window.origin}/access-denied`;
};

export const setTalentechAIToken = (aiToken) => {
  localStorage.setItem('@irecommend:aiToken', aiToken);
};

export const getTalentechAIToken = () => {
  const aiToken = localStorage.getItem('@irecommend:aiToken');
  return aiToken;
};

import React from 'react';
import { Button } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

const EmptyState = (props) => {
  const { img, width, alt, heading, description, callToActionType, size, buttonLabel, onSubmit } =
    props;

  const headerStyle = size === 'sm' ? styles.headingSmall : null;
  return (
    <div className={css(styles.settingsNoCategoryWrap)}>
      <img src={img} width={width} alt={alt} />
      <p className={css([styles.settingsNoCategoriesHeading, headerStyle])}>{heading}</p>
      <p className={css(styles.settingsNoCategoriesText)}>{description}</p>
      {callToActionType && callToActionType === 'link' ? (
        <p className={css(styles.link)} onClick={onSubmit}>
          {buttonLabel}
        </p>
      ) : callToActionType === 'button' ? (
        <Button label={buttonLabel} color="primary" size="md" onClick={onSubmit} />
      ) : null}
    </div>
  );
};

EmptyState.propTypes = {
  /**
   * Image handler
   */
  img: PropTypes.string.isRequired,
  /**
   * Heading handler
   */
  heading: PropTypes.string.isRequired,
  /**
   * Description handler
   */
  description: PropTypes.string.isRequired,
};

export default EmptyState;

const styles = StyleSheet.create({
  settingsNoCategoryWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '4rem',
    paddingTop: '4rem',
  },
  settingsNoCategoriesHeading: {
    fontWeight: 'bold',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '1.75rem',
  },
  headingSmall: {
    fontSize: '1rem',
  },
  settingsNoCategoriesText: {
    textAlign: 'center',
    fontSize: '.9rem',
    marginBottom: '4rem',
  },
  link: {
    textDecoration: 'underline',
    fontWeight: '600',
    cursor: 'pointer',
  },
});

export const isMatchingFilter = (position, currentFilter) => {
  return (
    currentFilter === 'all' ||
    (currentFilter === 'published' && !position.archived) ||
    (currentFilter === 'unpublished' && position.archived)
  );
};

export const isMatchingSearch = (position, searchTerm) => {
  return (
    position.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    position.contactname.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const filterTypes = {
  all: 'all',
  published: 'published',
  unpublished: 'unpublished',
};
